import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { navigate } from "gatsby";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/contact/Hero";

interface FormEntry {
  [k: string]: FormDataEntryValue;
}

const Contact1 = () => {
  function encode(data: FormEntry) {
    if (!data) return "";
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) +
          "=" +
          encodeURIComponent(data[key].toString())
      )
      .join("&");
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const formProps = Object.fromEntries(formData);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(formProps),
    })
      .then(() => navigate("/success"))
      .catch((error) => navigate("/error"));
  };
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero title="Get in touch">
          Please complete the form and are team will get back to you as soon as
          we can. Alternatively you can give us a call or use one of our direct
          to department email addresses.
        </Hero>
        <Section py={[20, 20, 50]}>
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <form
                  onSubmit={onSubmit}
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  data-netlify-recaptcha="true"
                  name="contact_us_form "
                >
                  <input type="hidden" name="form-name" value="contact_form" />
                  <Box mb={3}>
                    <Input
                      type="text"
                      placeholder="Your name"
                      name="name"
                      required
                    />
                  </Box>
                  <Box mb={3}>
                    <Input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      required
                    />
                  </Box>

                  <Box mb={3}>
                    <Input type="text" placeholder="Subject" name="subject" />
                  </Box>

                  <Box mb={3}>
                    <Input
                      type="text"
                      // @ts-expect-error could not find support of 'as' prop in typescript
                      as="textarea"
                      placeholder="Write your message"
                      rows={4}
                      name="description"
                      required
                    />
                  </Box>
                  <Input
                    hidden
                    type="text"
                    name="bot-field"
                    placeholder="Don’t fill this out if you’re human"
                    rows={4}
                  />
                  <Box mb={3} className="d-flex justify-content-center">
                    <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY!} />
                  </Box>
                  <Button
                    width="100%"
                    type="submit"
                    borderRadius={10}
                    onSubmit={onSubmit}
                  >
                    Send Message
                  </Button>
                </form>
              </Col>
              <Col
                lg={5}
                className="offset-lg-1 order-lg-2 mt-5 mt-lg-0 pt-lg-5"
              >
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Call us
                  </Title>
                  <Text>0870 240 5538</Text>
                </Box>
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Email us
                  </Title>
                  <Text>sales@shopsafe.uk</Text>
                  <Text>support@shopsafe.uk</Text>
                  <Text>accounts@shopsafe.uk</Text>
                </Box>
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Office Address
                  </Title>
                  <Text>Unit 2, Sandy Lane, The Broadway</Text>
                  <Text>Bury St Edmunds, Suffolk, IP31 3FA</Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact1;
